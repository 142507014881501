.sendbird-channel-list__body {
  margin-top: 40px;
}

.sendbird-message-content.incoming
  .sendbird-message-content__middle
  .sendbird-message-content__middle__body-container
  .sendbird-message-content__middle__body-container__created-at {
  right: -84px;
}

.sendbird-message-content.outgoing
  .sendbird-message-content__middle
  .sendbird-message-content__middle__body-container
  .sendbird-message-content__middle__body-container__created-at {
  left: -84px;
}
