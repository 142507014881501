.container {
  bottom: 20px;
  background-color: transparent !important;
}

.content-container {
  background-color: white;
  padding: 32px;
  border-radius: 4px;
}
