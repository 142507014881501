.spinner-container {
  width: 100%;
  min-height: 100%;
  top: 0;
  background-color: transparent;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}
.spinner-hide {
  display: none;
}
