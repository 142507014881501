@import './styles/variables';

.sendbird-message-status {
  position: relative;
  display: inline-flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;

  .sendbird-message-status__icon {
    position: relative;
    display: inline-block;
    top: -2px;
    &.hide-icon {
      display: none;
    }
  }

  .sendbird-message-status__text {
    position: relative;
    display: inline-block;
    margin-left: 4px;

    .sendbird-message-status__text__try-again {
      text-decoration: underline;
      &:hover {
        cursor: pointer;
      }
    }

    .sendbird-message-status__text__delete {
      text-decoration: underline;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
