.App {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  height: 100%;
  min-height: 100vh;
  background-image: linear-gradient(to right, #eaf1ff, #fdf8fb, #f3f2fe);
}

html,
body {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

html,
body,
#app,
#app > div {
  height: 100%;
}

::-ms-reveal {
  display: none;
}
