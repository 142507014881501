@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Light.ttf");
  src: local("Poppins"), local("Poppins"),
    url("./assets/fonts/Poppins-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Regular.ttf");
  src: local("Poppins"), local("Poppins"),
    url("./assets/fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Medium.ttf");
  src: local("Poppins"), local("Poppins"),
    url("./assets/fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-SemiBold.ttf");
  src: local("Poppins"), local("Poppins"),
    url("./assets/fonts/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Bold.ttf");
  src: local("Poppins"), local("Poppins"), url("./assets/fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
}

body {
  margin: 0;
  font-family: "Poppins", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Poppins", Arial, sans-serif;
}
