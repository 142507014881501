@import 'react-calendar/dist/Calendar.css';

.react-calendar__tile--now {
  background-color: transparent;
}

.react-calendar__navigation__label__labelText {
  font-weight: 600;
  color:#333333
}

.react-calendar__tile {
  &:hover {
    background-color: #F6F7FC !important;
  }
}

.react-calendar__month-view__days__day--weekend {
  color:#000;
}

.react-calendar__tile--range {
  background-color:#F6F7FC;
  font-weight: 600;
  color:#48A14D !important;
}

.startEndTile {
  &:hover {
    background-color: #48AC4D !important;
  }
  background-color:#48A14D !important;
  color:#fff !important;
}

.react-calendar__tile--rangeStart {
  
  @extend .startEndTile;
  border-radius: 4px 0px 0px 4px;
}

.react-calendar__tile--rangeEnd {
  @extend .startEndTile;
  border-radius: 0px 4px 4px 0px;
}

.react-calendar__tile--hasActive {
  background-color: #48A14D;
  color:#fff !important;
}

.react-calendar__navigation button:disabled {
  background-color: white !important;
}