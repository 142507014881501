.ProseMirror:focus {
  outline: none;
}
.ProseMirror {
  max-height: 150px;
  min-height: 150px;
  height: 100%;
  overflow-y: scroll;
  padding: 0;
  padding-top: 8px;
  padding-left: 8px;
  font-size: 14px;
}

.ProseMirror > p {
  margin: 0;
}
