//Classes targeting the react-big-calendar directly
@import 'react-big-calendar/lib/sass/styles';


//Adding custom border around calendar
.rbc-month-view, 
.rbc-day-bg + .rbc-day-bg,
.rbc-day-bg{
  border: 1px solid #DFE5F6;
}

.rbc-month-row + .rbc-month-row {
  border-top: 1px solid #DFE5F6;
}
.rbc-row-content {
  z-index: auto;
}

.rbc-header, .rbc-header + .rbc-header{
  border: none;
  padding: 0px;
}


//setting border radius around month view
.rbc-month-view {
  border-radius: 10px;
}
.rbc-header:first-child {
  border-radius: 10px 0 0 0;
}
.rbc-header:last-child {
  border-radius: 0 10px 0 0;
}




//out of range day bg change
.rbc-off-range-bg {
  background-color: transparent;
}



//targeting the week view header
.rbc-time-view .rbc-row {
  min-height: fit-content;
}

.rbc-button-link {
  width: 100%;
}

//targeting all the borders within week view

//around the week view container
.rbc-time-view {
  border: none;
}

.rbc-time-header-content {
  border-left: none;
}


//week header
.rbc-time-content {
  border-top: none;
}

.rbc-time-header.rbc-overflowing {
  margin-right: 0px !important;
  border-right: none;
}

//timeslot 
.rbc-time-gutter {
  .rbc-timeslot-group {
    border: none;
    .rbc-time-slot{
      font-weight: 500;
      font-size: 13px;
      color: #96A0BC;

    }
  }
}



.rbc-day-slot .rbc-time-slot {
  border-top: none;
}

.rbc-time-content > * + * > * {
  border-left: 2px solid #F6F7FC;
}

.rbc-timeslot-group {
  border-bottom: 1.2px solid #F6F7FC
}

//week view scrollbar
.rbc-time-content::-webkit-scrollbar {
  width: 17px;               /* width of the entire scrollbar */
}

.rbc-time-content::-webkit-scrollbar-track {
  background: #F9FAFD;        /* color of the tracking area */
}

.rbc-time-content::-webkit-scrollbar-thumb {
  background-color: #D9DDE1;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid #F9FAFD;  /* creates padding around scroll thumb */
}


.rbc-time-content::-webkit-scrollbar-button {
  width: 0px; //for horizontal scrollbar
  height: 0px; //for vertical scrollbar
}

.rbc-today {
  background-color: #e3e6f6;
}